import { ShopTypes } from '~/lib/data-contract';

export default function PriceRunner({ product }: { product: ShopTypes.ProductQueryProduct }) {
    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            height={35}
            width={100}
            src={`https://www.pricerunner.dk/widget/cheapest/image/40644?sku=${product.item?.itemNumber}&width=200`}
            alt="Billigst på PriceRunner"
        />
    );
}
