import { AnimatePresence, useReducedMotion, Variants } from 'framer-motion';
import { Portal } from 'react-portal';
import { useKeyPressEvent, useMedia } from 'react-use';
import { queries } from '~/theme';
import { Backdrop, Close, Content, Header, Icon, Main, Wrapper } from './styled';
import { IModalComponentProps, IModalProps } from './types';

export const Modal = ({ isOpen = false, onClose, ...rest }: IModalProps) => (
    <AnimatePresence initial mode="wait" onExitComplete={onClose}>
        {isOpen && <ModalComponent {...rest} />}
    </AnimatePresence>
);

const ModalComponent = ({
    handleClose,
    noHeader,
    children,
    noPadding,
    type,
}: IModalComponentProps) => {
    const modalType = type ?? 'sidebar';
    const shouldReduceMotion = useReducedMotion();
    const isTablet = useMedia(queries.xxs);

    // Close modal on escape key press when not in focus in input fields
    useKeyPressEvent('Escape', () => {
        const activeElement = document.activeElement;
        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return;
        }
        handleClose();
    });

    return (
        <Portal>
            <Wrapper>
                <Backdrop
                    variants={backdropVariants}
                    onClick={handleClose}
                    initial="closed"
                    animate="open"
                    exit="closed"
                    transition={transition}
                />

                <Main
                    modalType={modalType}
                    animate="open"
                    custom={[isTablet, shouldReduceMotion]}
                    exit="closed"
                    initial="closed"
                    noHeader={noHeader}
                    transition={transition}
                    variants={variants[modalType]}
                >
                    {noHeader ? null : (
                        <Header>
                            <Close onClick={handleClose}>
                                <Icon />
                            </Close>
                        </Header>
                    )}

                    <Content noPadding={noPadding}>{children}</Content>
                </Main>
            </Wrapper>
        </Portal>
    );
};

const inputs = ['input', 'select', 'textarea'];

const backdropVariants: Variants = {
    open: {
        opacity: 0.3,
    },
    closed: {
        opacity: 0,
    },
};

const sidebarVariants: Variants = {
    open: {
        x: 0,
        y: 0,
    },
    closed: ([isTablet, shouldReduceMotion]) =>
        shouldReduceMotion
            ? {}
            : {
                  x: isTablet ? '100%' : 0,
                  y: isTablet ? 0 : '100%',
              },
};

const fullscreenVariants: Variants = {
    open: {
        opacity: 1,
        scale: 1,
    },
    closed: {
        opacity: 0,
        scale: 0.5,
    },
};

const variants = {
    sidebar: sidebarVariants,
    fullscreen: fullscreenVariants,
    powerstep: fullscreenVariants,
    miniModal: fullscreenVariants,
};

const transition = {
    duration: 1000,
    type: 'spring',
    stiffness: 600,
    damping: 50,
};
