import styled from '@emotion/styled';
import { ProductStatusModal } from '~/features/ProductDetail';
import { Modal } from '~/shared/components/Modal';
import { closeModalFreight, setItemNo, useModalFreight } from '~/state/useModalFreight';

export default function PickUpModal() {
    const { isOpenModal } = useModalFreight();

    return (
        <Modal
            isOpen={isOpenModal}
            handleClose={() => {
                closeModalFreight();
                setItemNo('');
            }}
        >
            <StyledWrapper>
                <ProductStatusModal modalType={'freight'} />
            </StyledWrapper>
        </Modal>
    );
}

export const StyledWrapper = styled.div(({ theme }) => ({
    height: '100%',
    gridArea: 'one',
    display: 'grid',
    gridTemplateRows: 'repeat(3,auto) 1fr',
    rowGap: theme.space[5],
    paddingBottom: theme.space[2],
    '> div:first-of-type': {
        alignSelf: 'end',
        paddingBottom: theme.space[2],
    },
}));
