import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

type OptionTypes = {
    isActiveMedia?: boolean;
    oneChildOnly: boolean;
};

export const GalleryWrapper = styled.div(({ theme }) => ({
    '--columns': 8,
    '--gap': theme.space[1],
    display: 'grid',
    gridTemplateColumns: `repeat(var(--columns), minmax(0, 1fr))`,
    gap: 'var(--gap)',

    [breakpoints.md]: {
        '--columns': 2,
        '--gap': theme.space[4],
    },
}));

export const FeaturedImage = styled.div({
    position: 'relative',
    height: '50vh',
    minHeight: '300px',
    maxWidth: '70vw',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const FeaturedImageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    gridColumn: '1 / -1',
    backgroundColor: theme.colors.white,

    [breakpoints.md]: {
        display: 'none',
    },
}));

export const GridImage = styled.div<OptionTypes>(
    ({ theme, oneChildOnly }) => ({
        cursor: 'pointer',
        position: 'relative',
        display: oneChildOnly ? 'none' : 'block',
        backgroundColor: theme.colors.white,
        marginTop: 'auto',
        width: '100%',
        aspectRatio: '1',

        [breakpoints.md]: {
            display: 'block',
            maxHeight: 400,

            ':nth-of-type(2)': {
                height: 650,
                maxHeight: '70vh',
                minHeight: 400,
                gridColumn: '1 / -1',
            },
        },
    }),

    ifProp('isActiveMedia', ({ theme }) => ({
        ':after': {
            position: 'absolute',
            content: '""',
            width: '100%',
            height: 2,
            bottom: 0,
            backgroundColor: theme.colors.black,

            [breakpoints.md]: {
                display: 'none',
            },
        },
    })),
);

// export const VideoWrapper = styled(AspectRatio)<OptionTypes>(
//     () => ({
//         position: 'relative',

//         [`${StyledAspectRatioContent}`]: {
//             alignItems: 'center',
//             display: 'flex',

//             ['> *']: {
//                 height: '100%',
//             },
//         },
//     }),
//     ifProp('isFullWidth', {
//         [breakpoints.md]: {
//             gridColumn: 'span 2',
//             gridRow: 'span 2',
//         },
//     }),
//     ifProp('isActiveMedia', ({ theme }) => ({
//         borderBottom: `2px solid ${theme.colors.black}`,

//         [breakpoints.md]: {
//             borderBottom: '0px',
//         },
//     }))
// );

export const VideoBlocker = styled.div((props) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    zIndex: 2,

    [breakpoints.md]: {
        gridColumn: 'span 2',
        gridRow: 'span 2',
    },
}));
