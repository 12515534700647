import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Text } from '~/shared/components/Text';
import { breakpoints } from '~/theme';

export const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[3],
}));

export const BuyPriceWrapper = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

export const BuyPrice = styled(Text)<{ discount: boolean }>(
    ({ theme }) => ({
        color: theme.colors.nightBlack,
        textAlign: 'left',
        letterSpacing: '-0.5px',
        fontWeight: theme.fontWeights.normal,
        alignItems: 'baseline',
        display: 'flex',
        gap: theme.space[1],
    }),

    ifProp({ discount: true }, ({ theme }) => ({
        color: theme.colors.alert,
    })),
);

export const SalesUnit = styled(Text)<{ discount?: boolean }>(
    ({ theme }) => ({
        color: theme.colors.nightBlack,
        textTransform: 'lowercase',
        fontWeight: theme.fontWeights.normal,
        letterSpacing: '-0.2px',

        [breakpoints.md]: {
            fontSize: theme.fontSizes.squirrel,
            lineHeight: theme.lineHeights.squirrel,
        },
    }),

    ifProp({ discount: true }, ({ theme }) => ({
        color: theme.colors.alert,
    })),
);

export const NormalPrice = styled(Text)(({ theme }) => ({
    letterSpacing: '-0.3px',
    alignContent: 'left',
    fontWeight: theme.fontWeights.normal,
    alignItems: 'baseline',
    display: 'flex',
    gap: theme.space[1],

    [breakpoints.md]: {
        fontSize: theme.fontSizes.cat,
        lineHeight: theme.lineHeights.cat,
    },
}));
