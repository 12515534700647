import { AccordionMultipleProps, Root } from '@radix-ui/react-accordion';
import React, { Children, useState } from 'react';
import { AccordionContext } from './context/AccordionContext';

export type AccordionProps = Omit<AccordionMultipleProps, 'value' | 'defaultValue' | 'type'> & {
    /**
     * Allows a single or multiple accordions to be open at onces.
     * `single` will automaticlly close other open accordions,
     * when opening a new accordion
     */
    type?: 'single' | 'multiple';

    /**
     * Use AccordionItem as children
     */
    children: React.ReactNode;
};

/**
 * @example
 * <Accordion>
 *     <AccordionItem header="How to request a refund." initAsOpen={true}>
 *         Request a refund by...
 *     </AccordionItem>
 * </Accordion>
 */
export const Accordion = ({
    type = 'multiple',
    onValueChange,
    children = [],
    ...rest
}: AccordionProps) => {
    const [disableAnimation, setDisabledAnimation] = useState(true);

    // Extract initial states from children
    const [states, setOpenStates] = useState<string[]>(() => {
        const states: string[] = [];

        // Purely typescript hack
        const items = Array.isArray(children) ? children : [children];

        Children.forEach(items, (child) => {
            if (child?.props?.initAsOpen) {
                states.push(child.props.id);
            }
        });

        return states;
    });

    const renderAsList = Children.count(children) > 1;

    const setStates = (stateIds: string[]) => {
        const newStates = stateIds.filter((id, index) => {
            if (type === 'single') {
                return !states.includes(id);
            }

            // remove duplicates
            return stateIds.indexOf(id) === index;
        });

        setOpenStates(newStates);
    };

    const open = (stateId: string) => {
        setStates([...states, stateId]);
    };

    const close = (stateId: string) => {
        setStates(states.filter((id) => id !== stateId));
    };

    const toggle = (stateId: string) => {
        states.includes(stateId) ? close(stateId) : open(stateId);
    };

    const onValueChangeHandler = (values: string[]) => {
        const radixChangeValue = type === 'multiple' ? values : values[0] || '';

        // When user interacts with accordion, enable animations
        setDisabledAnimation(false);

        setStates(values);
        onValueChange && onValueChange(radixChangeValue as string & string[]);
    };

    return (
        <Root type="multiple" onValueChange={onValueChangeHandler} value={states} {...rest}>
            <AccordionContext.Provider
                value={{
                    states,
                    setStates,
                    toggle,
                    open,
                    close,
                    renderAsList,
                    disableAnimation,
                }}
            >
                {children}
            </AccordionContext.Provider>
        </Root>
    );
};
